// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MultipleRowSlick_slick-prev__hbOve:before, .MultipleRowSlick_slick-next__ChM0L:before {
    color: gray !important;
    font-size: 3rem !important;
}

.MultipleRowSlick_width-item__jqcZ6 {
    width: 100% !important;
}

.MultipleRowSlick_active_Film__DlQom {
    --tw-bg-opacity: 1;
    background-color: rgb(31 41 55 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

.MultipleRowSlick_none_active_Film__-CpLX {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(31 41 55 / var(--tw-text-opacity));
}`, "",{"version":3,"sources":["webpack://./src/components/RSlick/MultipleRowSlick.module.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,0BAA0B;AAC9B;;AAEA;IACI,sBAAsB;AAC1B;;AAGI;IAAA,kBAA6B;IAA7B,sDAA6B;IAA7B,oBAA6B;IAA7B;AAA6B;;AAI7B;IAAA,kBAA6B;IAA7B,yDAA6B;IAA7B,oBAA6B;IAA7B;AAA6B","sourcesContent":[".slick-prev:before, .slick-next:before {\n    color: gray !important;\n    font-size: 3rem !important;\n}\n\n.width-item {\n    width: 100% !important;\n}\n\n.active_Film {\n    @apply bg-gray-800 text-white;\n}\n\n.none_active_Film {\n    @apply bg-white text-gray-800;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"slick-prev": `MultipleRowSlick_slick-prev__hbOve`,
	"slick-next": `MultipleRowSlick_slick-next__ChM0L`,
	"width-item": `MultipleRowSlick_width-item__jqcZ6`,
	"active_Film": `MultipleRowSlick_active_Film__DlQom`,
	"none_active_Film": `MultipleRowSlick_none_active_Film__-CpLX`
};
export default ___CSS_LOADER_EXPORT___;
