// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* normal seat */
.seat {
  width: 35px;
  height: 35px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 15px;
  background-color: rgb(29, 182, 237);
  color: #fff;
}

.seatBooked {
  background-color: rgb(183, 172, 172);
  cursor: no-drop;
}

.seatBooking {
  background-color: rgb(228, 74, 8) !important;
}

.seatVip {
  background-color: rgb(4, 63, 122);
}

.seatVipBooked {
  background-color: rgb(183, 172, 172);
  cursor: no-drop;
}

.seatBookedByMe {
  /*seat that you booked */
  background-color: rgb(246, 246, 246);
  box-shadow: -1px 2px 18px -6px green;
  color: green !important;
  border: 1px solid green;

}`, "",{"version":3,"sources":["webpack://./src/pages/Checkout/Checkout.css"],"names":[],"mappings":"AAAA,gBAAgB;AAChB;EACE,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,YAAY;EACZ,mCAAmC;EACnC,WAAW;AACb;;AAEA;EACE,oCAAoC;EACpC,eAAe;AACjB;;AAEA;EACE,4CAA4C;AAC9C;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,oCAAoC;EACpC,eAAe;AACjB;;AAEA;EACE,wBAAwB;EACxB,oCAAoC;EAEpC,oCAAoC;EACpC,uBAAuB;EACvB,uBAAuB;;AAEzB","sourcesContent":["/* normal seat */\n.seat {\n  width: 35px;\n  height: 35px;\n  border: none;\n  border-radius: 5px;\n  cursor: pointer;\n  margin: 15px;\n  background-color: rgb(29, 182, 237);\n  color: #fff;\n}\n\n.seatBooked {\n  background-color: rgb(183, 172, 172);\n  cursor: no-drop;\n}\n\n.seatBooking {\n  background-color: rgb(228, 74, 8) !important;\n}\n\n.seatVip {\n  background-color: rgb(4, 63, 122);\n}\n\n.seatVipBooked {\n  background-color: rgb(183, 172, 172);\n  cursor: no-drop;\n}\n\n.seatBookedByMe {\n  /*seat that you booked */\n  background-color: rgb(246, 246, 246);\n  -webkit-box-shadow: -1px 2px 18px -6px green;\n  box-shadow: -1px 2px 18px -6px green;\n  color: green !important;\n  border: 1px solid green;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
