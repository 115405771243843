// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slick-dots-bottom{
    margin: 0 !important;
    padding: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/templates/HomeTemplate/Layout/HomeCarousel/HomeCarousel.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,qBAAqB;AACzB","sourcesContent":[".slick-dots-bottom{\n    margin: 0 !important;\n    padding: 0 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
