// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Checkout_trapezoid__ysHey {
  border-bottom: 50px solid gray;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  height: 0;
  width: 80%;
  opacity: 0.3;
  filter: drop-shadow(0px 128px 63px rgba(8, 8, 8, 0.38));
  -webkit-filter: drop-shadow(0px 128px 63px rgba(8, 8, 8, 0.38));
  -moz-filter: drop-shadow(0px 128px 63px rgba(8, 8, 8, 0.38));
}


`, "",{"version":3,"sources":["webpack://./src/pages/Checkout/Checkout.module.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,mCAAmC;EACnC,oCAAoC;EACpC,SAAS;EACT,UAAU;EACV,YAAY;EACZ,uDAAuD;EACvD,+DAA+D;EAC/D,4DAA4D;AAC9D","sourcesContent":[".trapezoid {\n  border-bottom: 50px solid gray;\n  border-left: 25px solid transparent;\n  border-right: 25px solid transparent;\n  height: 0;\n  width: 80%;\n  opacity: 0.3;\n  filter: drop-shadow(0px 128px 63px rgba(8, 8, 8, 0.38));\n  -webkit-filter: drop-shadow(0px 128px 63px rgba(8, 8, 8, 0.38));\n  -moz-filter: drop-shadow(0px 128px 63px rgba(8, 8, 8, 0.38));\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"trapezoid": `Checkout_trapezoid__ysHey`
};
export default ___CSS_LOADER_EXPORT___;
